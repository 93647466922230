import React from "react";
import '../styles/long1.css';
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ValueProp from "../components/ValueProp";
import SocialProof from "../components/SocialProof";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import Testimonials from "../components/Testimonials";
import CallToAction from "../components/CallToAction";

const IndexPage = () => (
    <Layout>
        <SEO title="MyTEXpress " />
        <div class="grid-container">
            <div class="left_space"></div>
            <div class="short1-main">
                <div class="long2-main1 register-bg-image">
                    <div className="logo-div">
                        <a aria-current="page" className="logo" href="/"><div className="logo-image"></div>
                        </a></div>
                    <div className="nav-belt register-header-section">
                        <div className="wp-block-column grid-column-parent">
                            <h3 className="register-header-title">Create Your MyTEXpress profile</h3>
                            <p className="register-header-text">Sign up to be the first to know about exclusive discounts and offers on the LBJ, NTE &amp; NTE 35W TEXpress Lanes.</p>
                        </div>
                        <div className="wp-block-column grid-column-children long2-form-section">
                            <div id='lightbox-inline-form-3c6d326b-d312-4b42-a1d9-501ae44bd6f5' class="register-formBorder"></div>
                        </div>
                    </div>
                </div>
                <div class="long2-main2">
                    <h3 className="form-section-header register-form-section-header">MyTEXpress Member Benefits</h3>
                    <div className="register-short-bot">
                        <div className="short-bot-left">
                            <div className="register-vector-img"></div>
                            <p className="register-form-section-title">Offers and Discounts</p>
                            <p className="register-form-section-text">Determine your eligibility to save time and money on the LBJ, NTE &amp; NTE 35W TEXpress Lanes.</p>
                        </div>
                        <div className="short-bot-right">
                            <div className="register-alerts-img"></div>
                            <h3 className="register-form-section-title">Updates and Traffic Alerts</h3>
                            <p className="register-form-section-text">Make sure you're the first to know about any construction updates or traffic alerts along your TEXpress Lanes route.</p>
                        </div>
                    </div>
                    <a href='https://www.texpresslanes.com/faq-page' target='_blank'><p className="faq">MyTEXpress FAQs</p></a>
                </div>
                <hr className="new4"></hr>
                <div className="long2-main3">
                    <p className="register-toll-tag-text">All Texas toll transponders are eligible on the TEXpress Lanes as well as the Oklahoma PIKEPASS.</p>
                    <div className="register-box">
                        <p className="register-box-text">Need help finding your toll transponder number?</p>
                        <p className="register-box-text">Here are two ways you can locate your toll transponder number:</p>
                        <div className="register-bot-img"></div>
                        <p className="register-box-text">Refer to the images above to find the information printed on your toll transponder </p>
                        <p className="register-box-text">(on your vehicle's windshield. The toll transponder number is printed on the side facing the driver in the vehicle.)</p>
                        <p className="register-box-text register-box-text-padding">You can also find your toll transponder number by logging into your respective toll transponder account. </p>
                        <div className="register-box-link"><a href="https://csc.ntta.org/olcsc/" target="_blank">Visit My TollTag Account</a>    <span className="mobile-line">|</span>    <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">Visit My TxTag Account</a>    <span className="mobile-line">|</span>    <a href="https://www.hctra.org/Login" target="_blank">Visit My EZ TAG Account</a></div>
                    </div>
                </div>
            </div>
            <div class="right_space"></div>
            <div class="footer">
                <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" /></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" /></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
            </div>
        </div>
    </Layout>
);

export default IndexPage;
